<template>
  <div
    class="flex place-items-start p-4 text-sm md:text-base text-red-700 border border-red-900/10 bg-red-50 rounded-2xl"
    role="alert"
  >
    <svg aria-hidden="true" class="w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20">
      <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path>
    </svg>
    <div class="flex-1">
      <slot />
    </div>
  </div>
</template>
